import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import qs from "query-string";
import * as yup from "yup";

import { signUpProvider, updateFormData } from "../../app/actions";
import { EMAIL, PASSWORD_MATCH, PASSWORD_REGEX, REQUIRED_FIELD } from "../../utils/validations";
import { executeRecaptcha, getPublicImage, getSystemTimezone } from "../../utils/helpers";
import { COACH_HOME_ROUTE, COACH_MANAGE_ORGANIZATION_ROUTE } from "../../utils/routes";
import { sendButtonClick, sendTextFieldFocusEvent } from "../../utils/analytics";
import { Box, Stack, Typography, useTheme } from "@mui/material";

import Button from "../button/Button";
import ButtonLink from "../button/ButtonLink";
import FormError from "../form_error/FormError";
import FormRow from "../form_row/FormRow";
import Image from "../image/Image";
import MFRHeader from "../../layouts/dashboard/header/MFRHeader";
import Page from "../minimal/Page";
import PasswordField from "../password_field/PasswordField";
import RecaptchaProvider from "../recaptcha_provider/RecaptchaProvider";
import SvgIconStyle from "../minimal/SvgIconStyle";
import TextInput from "../text_input/TextInput";
import { toast } from "../notifications/Toast";

const emailId = "email";

export default function ProviderSignup() {
    const dispatch = useDispatch();
    const theme = useTheme();
    const signup = useSelector(({ signup }) => signup);
    const [promoCode, setPromoCode] = useState(null);
    const initialFormValues = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirmPassword: "",
    };
    const mfrDomain =
        window.location.hostname.includes("mfrtherapists.com") || window.location.hostname.includes("mfrhealth.com");

    const schema = yup.object().shape({
        first_name: yup.string().required(REQUIRED_FIELD.message),
        last_name: yup.string().required(REQUIRED_FIELD.message),
        email: yup.string().required(REQUIRED_FIELD.message).email(EMAIL.message),
        password: yup.string().required(REQUIRED_FIELD.message).matches(PASSWORD_REGEX.value, PASSWORD_REGEX.message),
        confirmPassword: yup
            .string()
            .required(REQUIRED_FIELD.message)
            .oneOf([yup.ref("password"), null], PASSWORD_MATCH.message),
    });

    useEffect(() => {
        function assignPromoCode() {
            const {
                location: { search },
            } = window;

            if (search !== "") {
                let queryFields = qs.parse(search);

                setPromoCode(queryFields.promotion_code);
            }
        }

        assignPromoCode();
    }, []); // eslint-disable-line

    const handleFormFocus = () => (event) => {
        const {
            target: { id },
        } = event;

        if (id) sendTextFieldFocusEvent(id);
    };

    const handleFormChange = (status, setStatus, setFieldValue) => (event) => {
        const {
            target: { value, id },
        } = event;
        const fieldId = (id && id.length > 0 && id.split(" ")[0]) || "";
        let formData = {
            [fieldId]: value.trim(),
        };

        if (status && Object.keys(status).includes(fieldId)) {
            setStatus(fieldId, null);
        }

        dispatch(updateFormData(formData));
        setFieldValue(fieldId, value);
    };

    const handleFormSubmission = (values, actions, notifySuccess = false) => {
        const { first_name, last_name, email, password } = signup.formData;

        executeRecaptcha("providersignup")
            .then(async (recaptchaResponse) => {
                let params = {
                    ...initialFormValues,
                    first_name,
                    last_name,
                    email,
                    password,
                    tier: 1,
                    timezone: getSystemTimezone(),
                    tos_accepted: true,
                    registered_from_site: true,
                    "g-recaptcha-response": recaptchaResponse,
                };

                await sendButtonClick("sign_up_submit");

                const result = await dispatch(signUpProvider(params));
                const { error } = result;

                if (error) {
                    const errorMessage = error.response ? error.response.data : "Error creating account.";

                    actions.setStatus(error.response.data);

                    if (typeof errorMessage === "string") {
                        toast.error(errorMessage);
                    } else {
                        toast.error(
                            "Something went wrong signing you up. Please address the errors above and try again."
                        );
                    }
                } else {
                    if (promoCode) {
                        window.location.assign(
                            generatePath(`${COACH_MANAGE_ORGANIZATION_ROUTE}?promotion_code=:promoCode`, {
                                promoCode,
                            })
                        );
                    } else {
                        window.location.assign(COACH_HOME_ROUTE);
                    }
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };

    return (
        <RecaptchaProvider>
            <Page title="Myofascial Release Therapy Directory - Provider Signup" mfr={mfrDomain}>
                <MFRHeader />
                <div className="ts-provider-signup">
                    <div className="container mb-5">
                        <div className="ts-signup-container row no-gutters">
                            <div className="ts-signup-form col-12 col-lg-8 px-0 px-md-3 px-md-5 py-3 py-md-5">
                                <Stack sx={{ flexDirection: "row" }}>
                                    <Box sx={{ maxWidth: "75px", mr: 3 }}>
                                        <Image src={getPublicImage("ic_therapists_icon.webp")} />
                                    </Box>
                                    {initialFormValues && (
                                        <Formik
                                            initialValues={{
                                                first_name: initialFormValues.first_name.replace(/"/g, ""),
                                                last_name: initialFormValues.last_name.replace(/"/g, ""),
                                                email: initialFormValues[emailId],
                                                password: initialFormValues.password,
                                                confirmPassword: initialFormValues.confirmPassword,
                                            }}
                                            enableReinitialize={true}
                                            onSubmit={handleFormSubmission}
                                            validationSchema={schema}
                                        >
                                            {({
                                                handleSubmit,
                                                setStatus,
                                                setFieldValue,
                                                status,
                                                values,
                                                errors,
                                                touched,
                                            }) => (
                                                <div>
                                                    <div className="col-12 offset-lg-0 px-0 mb-2 mb-md-4">
                                                        <Stack sx={{ flexDirection: "row" }}>
                                                            <Box>
                                                                <Typography
                                                                    variant="h1"
                                                                    sx={{
                                                                        color: theme.palette.slate.main,
                                                                        fontSize: "48px !important",
                                                                    }}
                                                                >
                                                                    Myofascial Release Therapist Sign-Up
                                                                </Typography>
                                                                <Typography
                                                                    variant="h2"
                                                                    sx={{
                                                                        color: theme.palette.slate.main,
                                                                        fontWeight: 500,
                                                                        fontSize: "38px !important",
                                                                    }}
                                                                >
                                                                    for the MFR Directory.
                                                                </Typography>
                                                            </Box>
                                                        </Stack>
                                                        <Stack
                                                            sx={{ flexDirection: "row", alignItems: "center", mt: 3 }}
                                                        >
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    color: theme.palette.slate.main,
                                                                    fontSize: "34px",
                                                                    fontWeight: 700,
                                                                }}
                                                            >
                                                                $49 USD
                                                            </Typography>
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    fontSize: "28px",
                                                                    color: theme.palette.slate.main,
                                                                    ml: 1,
                                                                }}
                                                            >
                                                                Monthly
                                                            </Typography>
                                                        </Stack>
                                                        <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    color: theme.palette.slate.main,
                                                                }}
                                                            >
                                                                Or sign up for our annual subscription and save!
                                                            </Typography>
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    color: theme.palette.slate.main,
                                                                    fontWeight: 700,
                                                                    ml: 1,
                                                                }}
                                                            >
                                                                $499 USD Year
                                                            </Typography>
                                                        </Stack>
                                                        <Stack
                                                            sx={{
                                                                backgroundColor: theme.palette.grey[300],
                                                                borderRadius: "20px",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                p: 1,
                                                                mt: 2,
                                                                mb: 1,
                                                            }}
                                                        >
                                                            <SvgIconStyle
                                                                src={getPublicImage("ic_person_check.svg")}
                                                                sx={{ mr: 1, color: theme.palette.grey[700] }}
                                                            />
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    color: theme.palette.slate.main,
                                                                    fontWeight: 700,
                                                                }}
                                                            >
                                                                Up to 2 provider seats
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    color: theme.palette.slate.main,
                                                                    ml: 1,
                                                                }}
                                                            >
                                                                (add additional seats for a small monthly charge)
                                                            </Typography>
                                                        </Stack>
                                                        <Stack
                                                            sx={{
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                backgroundColor: theme.palette.grey[300],
                                                                borderRadius: "20px",
                                                                p: 1,
                                                                my: 1,
                                                            }}
                                                        >
                                                            <SvgIconStyle
                                                                src={getPublicImage("ic_clinic.svg")}
                                                                sx={{ mr: 1, color: theme.palette.grey[700] }}
                                                            />
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    color: theme.palette.slate.main,
                                                                    fontWeight: 700,
                                                                }}
                                                            >
                                                                1 physical clinic
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    color: theme.palette.slate.main,

                                                                    ml: 1,
                                                                }}
                                                            >
                                                                (add additional clinics for a small monthly charge)
                                                            </Typography>
                                                        </Stack>
                                                    </div>
                                                    <form className="col-12 px-0" onSubmit={handleSubmit}>
                                                        <FormRow bottomSpacing>
                                                            <TextInput
                                                                id="first_name form-field__first-name"
                                                                name="first_name"
                                                                className="form-field__first-name"
                                                                label="First Name"
                                                                onFocus={handleFormFocus()}
                                                                onChange={handleFormChange(
                                                                    status,
                                                                    setStatus,
                                                                    setFieldValue
                                                                )}
                                                                value={values.first_name}
                                                                error={errors.first_name}
                                                                touched={touched.first_name}
                                                                autoComplete="first_name"
                                                                required
                                                            />
                                                            {/* This component is used throughout the form to display server side and custom field errors */}
                                                            <FormError
                                                                show={
                                                                    errors &&
                                                                    !errors.first_name &&
                                                                    status &&
                                                                    status.first_name
                                                                }
                                                                message={status && status.first_name}
                                                            />
                                                        </FormRow>
                                                        <FormRow bottomSpacing>
                                                            <TextInput
                                                                id="last_name form-field__last-name"
                                                                name="last_name"
                                                                className="form-field__last-name"
                                                                label="Last Name"
                                                                onFocus={handleFormFocus()}
                                                                onChange={handleFormChange(
                                                                    status,
                                                                    setStatus,
                                                                    setFieldValue
                                                                )}
                                                                value={values.last_name}
                                                                error={errors.last_name}
                                                                touched={touched.last_name}
                                                                autoComplete="last_name"
                                                                required
                                                            />
                                                            <FormError
                                                                show={
                                                                    errors &&
                                                                    !errors.last_name &&
                                                                    status &&
                                                                    status.last_name
                                                                }
                                                                message={status && status.last_name}
                                                            />
                                                        </FormRow>
                                                        <FormRow bottomSpacing>
                                                            <TextInput
                                                                id={`${emailId} form-field__email`}
                                                                name={emailId}
                                                                label="Email"
                                                                onFocus={handleFormFocus()}
                                                                onChange={handleFormChange(
                                                                    status,
                                                                    setStatus,
                                                                    setFieldValue
                                                                )}
                                                                value={values[emailId]}
                                                                type="email"
                                                                error={errors[emailId]}
                                                                touched={touched[emailId]}
                                                                autoComplete={emailId}
                                                                required
                                                            />
                                                            <FormError
                                                                show={
                                                                    errors &&
                                                                    !errors[emailId] &&
                                                                    status &&
                                                                    status[emailId]
                                                                }
                                                                message={status && status[emailId]}
                                                            />
                                                        </FormRow>
                                                        <FormRow bottomSpacing>
                                                            <PasswordField
                                                                id="password form-field__password"
                                                                name="password"
                                                                className="form-field__password"
                                                                label="Password"
                                                                onFocus={handleFormFocus()}
                                                                onChange={handleFormChange(
                                                                    status,
                                                                    setStatus,
                                                                    setFieldValue
                                                                )}
                                                                value={values.password}
                                                                type="password"
                                                                error={errors.password}
                                                                touched={touched.password}
                                                                required
                                                            />
                                                            <FormError
                                                                show={
                                                                    errors &&
                                                                    !errors.password &&
                                                                    status &&
                                                                    status.password
                                                                }
                                                                message={status && status.password}
                                                            />
                                                        </FormRow>
                                                        <FormRow bottomSpacing>
                                                            <TextInput
                                                                id="confirmPassword form-field__confirmPassword"
                                                                name="confirmPassword"
                                                                label="Re-Enter Password"
                                                                onFocus={handleFormFocus()}
                                                                onChange={handleFormChange(
                                                                    status,
                                                                    setStatus,
                                                                    setFieldValue
                                                                )}
                                                                value={values.confirmPassword}
                                                                type="password"
                                                                error={errors.confirmPassword}
                                                                touched={touched.confirmPassword}
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        </FormRow>
                                                        <FormRow>
                                                            <Button
                                                                square
                                                                secondary
                                                                extraPadding
                                                                className="ts-signup-submit text-lg mt-2 mt-md-4"
                                                                analyticsClass="button__submit-provider-registration"
                                                                type="submit"
                                                                disabled={signup.providerSignupIsFetching}
                                                            >
                                                                Complete Registration
                                                            </Button>
                                                        </FormRow>
                                                        <FormRow>
                                                            <Typography variant="body2">
                                                                Searching for an MFR Therapist? Go to{" "}
                                                                <ButtonLink href="/search">Find a Therapist</ButtonLink>{" "}
                                                                to get connected.
                                                            </Typography>
                                                        </FormRow>
                                                    </form>
                                                </div>
                                            )}
                                        </Formik>
                                    )}
                                </Stack>
                            </div>
                        </div>
                        <div
                            bottomSpacing
                            className="container ts-signup-tos text-main text-xs mx-0 mx-md-4 my-0 my-md-4 px-3"
                        >
                            By completing your registration you are agreeing to the{" "}
                            <a
                                href="https://www.mfrtherapists.com/toc/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-secondary text-underline"
                            >
                                Terms and Conditions
                            </a>
                        </div>
                    </div>
                </div>
            </Page>
        </RecaptchaProvider>
    );
}
